import { FC } from 'react';
import IconProps from './IconProps';

const UsersGroup2Icon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36.3543 31.5C37.6655 31.5 38.7085 30.6749 39.645 29.5209C41.5621 27.159 38.4145 25.2714 37.214 24.347C35.9938 23.4073 34.6312 22.875 33.2498 22.75M31.4998 19.25C33.916 19.25 35.8748 17.2912 35.8748 14.875C35.8748 12.4588 33.916 10.5 31.4998 10.5"
        stroke="currentColor"
        strokeWidth="2.625"
        strokeLinecap="round"
      />
      <path
        d="M5.64544 31.5C4.33414 31.5 3.29118 30.6749 2.3547 29.5209C0.437659 27.159 3.58518 25.2714 4.78563 24.347C6.00595 23.4073 7.36852 22.875 8.75001 22.75M9.62501 19.25C7.20876 19.25 5.25001 17.2912 5.25001 14.875C5.25001 12.4588 7.20876 10.5 9.62501 10.5"
        stroke="currentColor"
        strokeWidth="2.625"
        strokeLinecap="round"
      />
      <path
        d="M14.1464 26.4446C12.3583 27.5503 7.66998 29.8079 10.5255 32.6329C11.9204 34.013 13.4739 35 15.4271 35H26.5725C28.5257 35 30.0791 34.013 31.4741 32.6329C34.3295 29.8079 29.6413 27.5503 27.8531 26.4446C23.66 23.8518 18.3394 23.8518 14.1464 26.4446Z"
        stroke="currentColor"
        strokeWidth="2.625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.1248 13.125C27.1248 16.5077 24.3825 19.25 20.9998 19.25C17.617 19.25 14.8748 16.5077 14.8748 13.125C14.8748 9.74225 17.617 7 20.9998 7C24.3825 7 27.1248 9.74225 27.1248 13.125Z"
        stroke="currentColor"
        strokeWidth="2.625"
      />
    </svg>
  );
};

export default UsersGroup2Icon;
